import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Button from "../../components/button";
import AboutItem from "./about-item";

About.propTypes = {
  data: PropTypes.array,
};

function About(props) {
  const { data, subtitle, title, desc, link } = props;

  // const [dataBlock] = useState({
  //   subtitle: "VPS Hosting",
  //   title: "Our Virtual Private Servers",
  //   desc: "Get access to a remote desktop of your server with the operating system of your choice. This way, you can install anything you want on your server located in Dallas, Texas.",
  // });

  const [dataBlock] = useState({
    subtitle: subtitle,
    title: title,
    desc: desc,
  });

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title st2">
                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                <h4 className="title">{dataBlock.title}</h4>
              </div>
              <p>{dataBlock.desc}</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <a
                  href="#"
                  className="tf-button-st2 btn-effect"
                  data-toggle="modal"
                  data-target="#popup_bid"
                >
                  <span className="effect">VPS Hosting</span>
                </a>
                <a href="#" className="tf-button-st2 btn-effect">
                  <span className="boder-fade"></span>
                  <span className="effect">Minecraft Hosting</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div
              className="wrap-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {data.map((item) => (
                <AboutItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
