const dataWork = [
  {
    id: 1,
    numb: '01',
    title: 'Powerful Panel',
    text:
      'Our advanced control panel ensures, you can manage your services in a secure way!',
  },
  {
    id: 2,
    numb: '02',
    title: 'DDoS Protection',
    text: 'We have you covered with DDoS protection FREE of charge.',
  },
  {
    id: 3,
    numb: '03',
    title: 'Enterprise Hardware',
    text:
      'We only use Enterprise hardware, to provide the best experince at a affordable price!',
  },
  {
    id: 4,
    numb: '04',
    title: 'Instant Server Activation',
    text:
      'Enjoy instant activation across all our services! Get going immediately after paying.',
  },
  {
    id: 5,
    numb: '05',
    title: '99% Uptime Gaurentee',
    text: "Don't worry our network is engineered to provide SLA is always met.",
  },
  {
    id: 6,
    numb: '06',
    title: 'Support 24/7',
    text:
      'Have an issue or concern? Open a support ticket with us anytime and get a quick response.',
  },
]
export default dataWork
