import img1 from '../../assets/images/partner/img-1.svg'
import img2 from '../../assets/images/partner/partner-2.png'
import img3 from '../../assets/images/partner/partner-3.png'
import img4 from '../../assets/images/partner/NTT.png'
import img6 from '../../assets/images/partner/ubuntu.png'
import img9 from '../../assets/images/partner/centos.jpg'

const dataPartner = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img6,
  },
  {
    img: img9,
  },
]

export default dataPartner
