const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  {
    id: 2,
    name: 'VPS Hosting',
    links: '/vps',
  },
  {
    id: 5,
    name: 'Game Servers',
    links: '#',
    namesub: [
      {
        id: 3,
        sub: 'Minecraft',
        links: '/minecraft',
      },
      {
        id: 4,
        sub: 'Rust',
        links: '/rust',
      },
      {
        id: 5,
        sub: 'ARK: Survival Evolved',
        links: '/ark',
      },
    ],
  },
  {
    id: 3,
    name: 'Company',
    links: '#',
    namesub: [
      {
        id: 2,
        sub: 'About us',
        links: '/about-us',
      },
      {
        id: 3,
        sub: 'Terms and Conditions',
        links: '/terms',
      },
      {
        id: 6,
        sub: 'Privacy Policy',
        links: '/privacy',
      },
      {
        id: 4,
        sub: 'Service Level Agreement',
        links: '/sla',
      },
      {
        id: 5,
        sub: 'Acceptable Use Policy',
        links: '/aup',
      },
    ],
  },
  {
    id: 4,
    name: 'Contact',
    links: '/contact',
  },
]

export default menus
