import Home from './Home'
import Contact from './Contact'
import AboutUs from './AboutUs'
import BudgetMinecraft from './BudgetMinecraft'
import PremiumMinecraft from './PremiumMinecraft'
import VPS from './VPS'
import Terms from './Terms'
import SLA from './SLA'
import AUP from './AUP'
import Privacy from './Privacy'
import Minecraft from './Minecraft'
import ARK from './ARK'
import Rust from './Rust'

const routes = [
  { path: '/', component: <Home /> },
  { path: '/minecraft', component: <Minecraft /> },
  { path: '/ARK', component: <ARK /> },
  { path: '/rust', component: <Rust /> },
  { path: '/budget-minecraft', component: <BudgetMinecraft /> },
  { path: '/premium-minecraft', component: <PremiumMinecraft /> },
  { path: '/vps', component: <VPS /> },
  { path: '/about-us', component: <AboutUs /> },
  { path: '/terms', component: <Terms /> },
  { path: '/privacy', component: <Privacy /> },
  { path: '/SLA', component: <SLA /> },
  { path: '/AUP', component: <AUP /> },
  { path: '/contact', component: <Contact /> },
]

export default routes
