import bgImg3 from '../images/background/minecraft.png'

const dataSlider = [
  {
    id: 1,
    title: 'Minecraft Server Hosting',
    desc:
      'Experience the ultimate Minecraft gaming experience with our fast processors and high-speed disks located in Dallas, Texas. Servers start as low as $1.50/Month',
    bgImg: bgImg3,
    classAction: 'three',
    link1heading: 'Budget Minecraft Hosting',
    link1: '/budget-minecraft',
    link2heading: 'Premium i9 Minecraft Hosting',
    link2: '/premium-minecraft',
  },
]

export default dataSlider
