import React from "react";
import dataAbout from "../assets/data/data-about";
import dataPartner from "../assets/data/data-partner";
import PageTitle from "../components/pagetitle";
import About from "../features/about";
import Partner from "../features/partner";
import data from "../assets/data/data-ark";
import Products from "../features/products";
import FAQ from "../features/faq/home-v2";
import dataFaq from "../assets/data/data-faq";
import img from "../assets/images/background/ark.jpg";

function ARK(props) {
  return (
    <div className="about-v2">
      <PageTitle
        title="ARK Hosting"
        subtitle="Boost your online performance and reliability with our ARK: Survival Evolved hosting servers (Powered by Intel® Core™ i9 Processor)"
        img={img}
      />

      <Products data={data} />

      <About
        data={dataAbout}
        subtitle="ARK Hosting"
        title="Our ARK: Survival Evolved"
        desc="Boost your online performance and reliability with our Rust hosting servers (Powered by Intel® Core™ i9 Processor)"
      />

      <FAQ data={dataFaq} />

      <Partner data={dataPartner} />
    </div>
  );
}

export default ARK;
