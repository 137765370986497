import React from "react";
import dataAbout from "../assets/data/data-about";
import dataPartner from "../assets/data/data-partner";
import dataTeam from "../assets/data/data-team";
import PageTitle from "../components/pagetitle";
import About from "../features/about";
import Partner from "../features/partner";
import Team from "../features/team/home-v2";
import data from "../assets/data/data-budget-minecraft";
import Products from "../features/products";
import FAQ from "../features/faq/home-v2";
import dataFaq from "../assets/data/data-faq";
import img from "../assets/images/background/minecraft.png";

function BudgetMinecraft(props) {
  return (
    <div className="about-v2">
      <PageTitle
        title="Budget Minecraft Hosting"
        subtitle="Minecraft Hosting Powered by Intel Xeon processors"
        img={img}
      />

      <Products data={data} />

      <About
        data={dataAbout}
        subtitle="Minecraft Hosting"
        title="Our Budget Minecraft Servers"
        desc="Get access to your personal Minecraft Server to play with your friends Powered by Intel Xeon processors"
      />

      <FAQ data={dataFaq} />

      <Partner data={dataPartner} />
    </div>
  );
}

export default BudgetMinecraft;
