import React from "react";
import Button from "../components/button";

function Page404(props) {
  return (
    <section className="page-title p404">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="breadcrumbs center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <strong>
                <p>
                  Note that we have updated our website and the client area has
                  been moved to{" "}
                  <a href="https://portal.vastnode.net" target="_blank">
                    https://portal.vastnode.net
                  </a>
                </p>
              </strong>
              <h1>404</h1>
              <h2>Page Not Found</h2>
              <p>We can’t find the page that you’re looking for.</p>
              <div className="row">
                <div className="col-6">
                  <Button title="Back To Homepage" path="/" />
                </div>
                <div className="col-6">
                  <Button
                    title="Client area"
                    href="https://portal.vastnode.net"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Page404;
