import bgImg1 from '../images/background/vps.png'
import bgImg3 from '../images/background/minecraft.png'
import bgImg5 from '../images/background/ark.jpg'

const dataSlider = [
  {
    id: 1,
    title: 'Virtual Private Servers',
    desc:
      'Our Dallas VPS hosting is equipped with fast processors and disks to provide you with top-notch performance and speed. Servers start as low as $2/Month',
    bgImg: bgImg1,
    classAction: 'three',
    link1heading: 'VPS Hosting',
    link1: 'vps',
  },

  {
    id: 3,
    title: 'Minecraft Server Hosting',
    desc:
      'Experience the ultimate Minecraft gaming experience with our fast processors and high-speed disks located in Dallas, Texas. Servers start as low as $1.50/Month',
    bgImg: bgImg3,
    classAction: 'three',
    link1heading: 'Budget Minecraft Hosting',
    link1: 'budget-minecraft',
    link2heading: 'Premium i9 Minecraft Hosting',
    link2: 'premium-minecraft',
  },
  {
    id: 4,
    title: 'ARK: Survival Evolved',
    desc:
      'Boost your online performance and reliability with our ARK: Survival Evolved hosting servers (Powered by Intel® Core™ i9 Processor)',
    bgImg: bgImg5,
    classAction: 'three',
    link1heading: 'Rust Hosting',
    link1: 'rust',
  },
]

export default dataSlider
