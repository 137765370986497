import React from "react";
import PageTitle from "../components/pagetitle";

function Contact(props) {
  return (
    <div>
      <PageTitle title="Contact Us" />

      <section className="tf-section tf-contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div
                className="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="tf-title st2 m-b17">
                  <h4 className="title">Have a question </h4>
                </div>
                <p className="m-r-40">
                  Fill up a Form and our team will get back to within 24 hrs
                </p>
                <a
                  href="https://portal.vastnode.net/contact.php"
                  className="tf-button btn-effect"
                >
                  <span className="boder-fade"></span>
                  <span className="effect">Send Message</span>
                </a>
              </div>
            </div>
            <div className="col-xl-7  col-md-12">
              <div
                className="contact-details"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div className="adress wrap-fx">
                  <div className="location">
                    <h6>Location</h6>
                    <ul>
                      <li>
                        Infomart, 1950 <br /> N Stemmons Fwy suite 1034, Dallas,
                        TX 75207, United States
                      </li>
                    </ul>
                  </div>
                  <div className="mail">
                    <h6>Contact Us</h6>
                    <ul>
                      <li>
                        <a href="mailto:support@vastnode.net">
                          support@vastnode.net
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flat-map wow fadeIn animated"
              data-wow-delay="0.3ms"
              data-wow-duration="1000ms"
            >
              <iframe
                title="map"
                className="map-content"
                src="https://www.google.com/maps/embed/v1/place?q=Infomart,+1950+N+Stemmons+Fwy+suite+1034,+Dallas,+TX+75207,+United+States&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                width="1720"
                height="655"
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
