const dataRoadmap = [
  {
    id: 1,
    time: 'Q1 2023',
    list: [
      {
        text: 'Management / Staff Change',
      },
      {
        text: 'Server Upgrades (i9 Servers)',
      },
      {
        text: 'Network Upgrade',
      },
      {
        text: 'Pricing Restructure',
      },
    ],
    positon: 'left',
    style: 'normal',
  },
  {
    id: 2,
    time: 'Q2 2023',
    list: [
      {
        text: '10Gbps offerings',
      },
      {
        text: 'New Game Selections',
      },
      {
        text: 'New Marketing Ads',
      },
      {
        text: 'Deploy 2nd Location',
      },
    ],
    positon: 'right',
    style: 'normal',
  },
  {
    id: 3,
    time: 'Q3 2023',
    list: [
      {
        text: 'AMD Epyc Servers',
      },
      {
        text: 'Deploy 3rd Location',
      },
      {
        text: 'Upgrade Dallas VPS Nodes',
      },
      {
        text: 'Add new network routes',
      },
    ],
    positon: 'left',
  },
  {
    id: 4,
    time: 'Q4 2023',
    list: [
      {
        text: 'End of year give aways',
      },
      {
        text: 'Add more games',
      },
      {
        text: 'Potential VastNode Tournaments',
      },
      {
        text: 'Gifts / Prizes',
      },
    ],
    positon: 'right',
  },
]

export default dataRoadmap
