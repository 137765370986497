import React from "react";

import "../scss/components/section.scss";
import "../scss/components/box.scss";
import Slider from "../components/slider";
import dataSlider from "../assets/data/data-minecraft";
import About from "../features/about";
import dataAbout from "../assets/data/data-about";
import RoadMap from "../features/roadmap";
import dataRoadmap from "../assets/data/data-roadmap";
import Work from "../features/work";
import dataWork from "../assets/data/data-work";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/data/data-partner";
import FAQ from "../features/faq/home-v2";
import dataFaq from "../assets/data/data-faq";

function Minecraft(props) {
  return (
    <div className="home-1">
      <Slider data={dataSlider} />

      <About
        data={dataAbout}
        subtitle="Server Hosting"
        title="Quality game server and virtual private server provider"
        desc="Get access to a remote desktop of your server with the operating system of your choice or a game server of your choice. This way, you can install anything you want on your server located in Dallas, Texas."
      />
      <RoadMap data={dataRoadmap} />

      <Work data={dataWork} />

      <Partner data={dataPartner} />

      <FAQ data={dataFaq} />
    </div>
  );
}

export default Minecraft;
