const premiumMinecraft = [
  {
    id: 1,
    title: 'MC-01',
    desc:
      '1 vCore Intel i9\n1 GB RAM\n15 GB NVMe\n1 Gbps unlimited bandwidth\n1 Backup slot\n2 MySQL\nPath DDoS protection',
    price: '$7.50 USD',
    billing: 'Quarterly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-01-1',
  },
  {
    id: 2,
    title: 'MC-02',
    desc:
      '1 vCore Intel i9\n2 GB RAM\n30 GB NVMe\n1 Gbps unlimited bandwidth\n2 Backup slots\n4 MySQL\nPath DDoS protection',
    price: '$15.00 USD',
    billing: 'Quarterly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-02-2',
  },
  {
    id: 3,
    title: 'MC-03',
    desc:
      '2 vCore Intel i9\n4 GB RAM\n50 GB NVMe\n1 Gbps unlimited bandwidth\n3 Backup slots\n6 MySQL\nPath DDoS protection',
    price: '$10.00 USD',
    billing: 'Monthly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-03-2',
  },
  {
    id: 4,
    title: 'MC-04',
    desc:
      '2 vCore Intel i9\n8 GB RAM\n75 GB NVMe\n1 Gbps unlimited bandwidth\n4 Backup slots\n8 MySQL\nPath DDoS protection',
    price: '$20.00 USD',
    billing: 'Monthly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-04-2',
  },
  {
    id: 5,
    title: 'MC-05',
    desc:
      '4 vCore Intel i9\n12 GB RAM\n200 GB NVMe\n1 Gbps unlimited bandwidth\n5 Backup slots\n10 MySQL\nPath DDoS protection',
    price: '$24.00 USD',
    billing: 'Monthly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-05-2',
  },
  {
    id: 6,
    title: 'MC-06',
    desc:
      '6 vCores Intel i9\n16 GB RAM\n200 GB NVMe\n1 Gbps unlimited bandwidth\n6 Backup slots\nUnlimited MySQL\nPath DDoS protection',
    price: '$32.00 USD',
    billing: 'Monthly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-06-2',
  },
  {
    id: 7,
    title: 'MC-07',
    desc:
      '6 vCores Intel i9\n20 GB RAM\n250 GB NVMe\n1 Gbps unlimited bandwidth\n7 Backup slots\nUnlimited MySQL\nPath DDoS protection',
    price: '$40.00 USD',
    billing: 'Monthly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-07-2',
  },
  {
    id: 8,
    title: 'MC-08',
    desc:
      '8 vCores Intel i9\n32 GB RAM\n250 GB NVMe\n1 Gbps unlimited bandwidth\n8 Backup slots\nUnlimited MySQL\nPath DDoS protection',
    price: '$64.00 USD',
    billing: 'Monthly',
    processor: 'Intel i9',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/premium-minecraft-hosting/mc-08-2',
  },
]

export default premiumMinecraft
