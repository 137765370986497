import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import RoadMapItem from "./roadmap-item";
RoadMap.propTypes = {
  data: PropTypes.array,
};

function RoadMap(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "Life Cycle",
    title: "VastNode Re-brand",
  });

  return (
    <section
      className="tf-section tf-roadmap"
      style={{ backgroundPosition: "bottom" }}
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title mb-30"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">{dataBlock.subtitle}</p>
              <h4 className="title">{dataBlock.title}</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div style={{ textAlign: "center" }}>
              <video
                src="https://portal.vastnode.net/commercial_1.mp4"
                height="512"
                controls="controls"
                autoPlay={false}
              />
            </div>
            <div className="roadmap ">
              {data.map((item) => (
                <RoadMapItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoadMap;
