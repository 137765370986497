import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Button from "../../components/button";
import ProductItem from "./product-item";

Products.propTypes = {
  data: PropTypes.array,
};

function Products(props) {
  const { data } = props;

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="col-md-12">
          <div data-aos="fade-up" data-aos-duration="800">
            <div className="row">
              {data.map((item) => (
                <ProductItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Products;
