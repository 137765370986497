import React from "react";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/data/data-partner";

function Privacy(props) {
  return (
    <div>
      <PageTitle title="Privacy Policy" subtitle="Ammended: 30th June 2022" />

      <section className="tf-section team-detail ">
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-12">
              <div
                className="info-detail"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <p className="sub">Privacy Policy</p>
                <div className="box">
                  <h1>Overview</h1>
                  <blockquote>
                    VastNode has created this privacy statement in order to
                    demonstrate our firm commitment to your privacy. The
                    following discloses the information gathering and
                    dissemination practices for this Web site. We take our
                    customers privacy very seriously. Information such as
                    address, phone, etc. will not be given out. However, we
                    reserve the right to monitor your account. We will also use
                    given information to verify you as the account holder in the
                    event you forgot your password
                  </blockquote>
                  <h1>Information Automatically Logged</h1>
                  <ul>
                    <li>
                      <p>
                        We use your IP address to help diagnose problems with
                        our server and to administer our Web site. We use this
                        information for no other reason.
                      </p>
                    </li>
                  </ul>
                  <h1>Order Forms</h1>
                  <ul>
                    <li>
                      <p>
                        Our site uses an order form for customers to request
                        services. (128 bit encryption). We collect sensitive
                        information which is used only for our purpose, not
                        third party receives any type of information from us.
                        Contact information from the order forms is used to get
                        in touch with the customer when necessary. Billing
                        information that is collected is used to bill the user
                        for services. Unique identifiers are collected from Web
                        site visitors to verify the user’s identity. Demographic
                        and profile data is also collected at our site. We use
                        this data to tailor our visitor’s experience at our site
                        showing them content that we think they might be
                        interested in, and displaying the content according to
                        their preferences.
                      </p>
                    </li>
                  </ul>
                  <h1>Security</h1>
                  <ul>
                    <li>
                      <p>
                        This site has security measures in place to protect the
                        loss, misuse, and alteration of the information under
                        our control. We use strong SSL encryption to ensure your
                        privacy.
                      </p>
                    </li>
                  </ul>
                  <h1>Third Parties</h1>
                  <ul>
                    <li>
                      <p>
                        Information collected on this site is strictly for our
                        use, NO OTHER OUTSIDE PERSONS MAY VIEW YOUR PERSONAL
                        INFORMATION SUCH AS BILLING INFORMATION, ETC.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="box contact">
                  <div className="h7">Contact</div>
                  <p>
                    Infomart, 1950 N Stemmons Fwy suite 1034, Dallas, TX 75207,
                    United States
                  </p>
                  <a href="mailto:support@vastnode.net">
                    <p>support@vastnode.net</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-section tf-contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div
                className="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="tf-title st2 m-b17">
                  <h4 className="title">Have a question </h4>
                </div>
                <p className="m-r-40">
                  Fill up a Form and our team will get back to within 24 hrs
                </p>
                <a href="#" className="tf-button btn-effect">
                  <span className="boder-fade"></span>
                  <span className="effect">Send Message</span>
                </a>
              </div>
            </div>
            <div className="col-xl-7  col-md-12">
              <div
                className="contact-details"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div className="adress wrap-fx">
                  <div className="location">
                    <h6>Location</h6>
                    <ul>
                      <li>
                        Infomart, 1950 <br /> N Stemmons Fwy suite 1034, Dallas,
                        TX 75207, United States
                      </li>
                    </ul>
                  </div>
                  <div className="mail">
                    <h6>Contact Us</h6>
                    <ul>
                      <li>
                        <a href="mailto:support@vastnode.net">
                          support@vastnode.net
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flat-map wow fadeIn animated"
              data-wow-delay="0.3ms"
              data-wow-duration="1000ms"
            >
              <iframe
                title="map"
                className="map-content"
                src="https://www.google.com/maps/embed/v1/place?q=Infomart,+1950+N+Stemmons+Fwy+suite+1034,+Dallas,+TX+75207,+United+States&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                width="1720"
                height="655"
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <Partner data={dataPartner} />
    </div>
  );
}

export default Privacy;
