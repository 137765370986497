import React from "react";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/data/data-partner";

function AUP(props) {
  return (
    <div>
      <PageTitle
        title="Acceptable Use Policy"
        subtitle="Ammended: 30th June 2022"
      />

      <section className="tf-section team-detail ">
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-12">
              <div
                className="info-detail"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <p className="sub">Acceptable Use Policy</p>
                <div className="box">
                  <h1>Minecraft Hosting</h1>
                  <p>
                    When purchasing a Minecraft service from VastNode you
                    automatically agree to Mojang Studios End Of User License
                    agreement.
                  </p>
                  <p> </p>
                  <p>
                    Disk space provided by VastNode on Minecraft services is
                    described as 'Unmetered' & 'Unlimited'.
                  </p>
                  <ul>
                    <li>
                      <p>
                        All Minecraft services come with a soft limit of 25GB
                        for storage, VastNode reserves the right to remove any
                        content from the servers that is not releated to the
                        service. **NOTE:** Using storage backups is also not
                        allowed.
                      </p>
                    </li>
                    <li>
                      <p>
                        If your services is found to use large amounts of CPU,
                        RAM or Bandwidth, VastNode reserves the right to suspend
                        or place limitation.
                      </p>
                    </li>
                  </ul>
                  <h2>Virtual Private Server (VPS) Hosting</h2>
                  <p>
                    Bandwith provide by VastNode on VPS hosting is described as
                    'Unmetered' & 'Unlimited'.
                  </p>
                  <ul>
                    <li>
                      <p>
                        All VPS services come with a soft limit of 20TB of
                        bandwith.
                      </p>
                    </li>
                    <li>
                      <p>
                        If your services is found to use large amounts of CPU,
                        RAM or Bandwidth, VastNode reserves the right to suspend
                        or place limitation.
                      </p>
                    </li>
                    <li>
                      <p>
                        On purchase you agree to not use any illegal or Nulled
                        websites, Illegal software/porgrams or use your service
                        for email spamming.
                      </p>
                    </li>
                    <li>
                      <p>
                        Prohibited Programs include; Port Screening,
                        HostSpaming, DDos Attacks.
                      </p>
                    </li>
                    <li>
                      <p>
                        On purchase you agree to comply with operating systems
                        TOS.
                      </p>
                    </li>
                  </ul>
                  <h2>Discord Bot Hosting</h2>
                  <p>
                    When purchasing a Discord Bot service from VastNode you
                    automatically agree to Discords Terms of services.
                  </p>
                  <p> </p>
                  <p>
                    Disk space provided by VastNode on Discord Bot services is
                    described as 'Unmetered' & 'Unlimited'.
                  </p>
                  <ul>
                    <li>
                      <p>
                        All Discord Bot services come with a soft limit of 10GB
                        for storage, VastNode reserves the right to remove any
                        content from the servers that is not releated to the
                        service. **NOTE:** Using storage backups is also not
                        allowed.
                      </p>
                    </li>
                    <li>
                      <p>
                        If your services is found to use large amounts of CPU,
                        RAM, API Spaming or Bandwidth, VastNode reserves the
                        right to suspend or place limitation.
                      </p>
                    </li>
                  </ul>
                  <h2>Web Hosting</h2>
                  <p> </p>
                  <p>
                    Disk space provided by VastNode on Web services is described
                    as 'Unmetered' & 'Unlimited'.
                  </p>
                  <ul>
                    <li>
                      <p>
                        All Web services with the word 'Unmetered' or
                        'Unlimited' come with a soft limit of 25GB for storage &
                        1TB Bandiwth, VastNode reserves the right to remove any
                        content from the servers that is not releated to the
                        service. **NOTE:** Using storage backups is also not
                        allowed.
                      </p>
                    </li>
                    <li>
                      <p>
                        On purchase you agree to not use any illegal or Nulled
                        websites, Illegal software/porgrams or use your service
                        for email spamming.
                      </p>
                    </li>
                    <li>
                      <p>
                        If your services is found to use large amounts of CPU,
                        RAM or Bandwidth, VastNode reserves the right to suspend
                        or place limitation.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="box contact">
                  <div className="h7">Contact</div>
                  <p>
                    Infomart, 1950 N Stemmons Fwy suite 1034, Dallas, TX 75207,
                    United States
                  </p>
                  <a href="mailto:support@vastnode.net">
                    <p>support@vastnode.net</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-section tf-contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div
                className="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="tf-title st2 m-b17">
                  <h4 className="title">Have a question </h4>
                </div>
                <p className="m-r-40">
                  Fill up a Form and our team will get back to within 24 hrs
                </p>
                <a href="#" className="tf-button btn-effect">
                  <span className="boder-fade"></span>
                  <span className="effect">Send Message</span>
                </a>
              </div>
            </div>
            <div className="col-xl-7  col-md-12">
              <div
                className="contact-details"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div className="adress wrap-fx">
                  <div className="location">
                    <h6>Location</h6>
                    <ul>
                      <li>
                        Infomart, 1950 <br /> N Stemmons Fwy suite 1034, Dallas,
                        TX 75207, United States
                      </li>
                    </ul>
                  </div>
                  <div className="mail">
                    <h6>Contact Us</h6>
                    <ul>
                      <li>
                        <a href="mailto:support@vastnode.net">
                          support@vastnode.net
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flat-map wow fadeIn animated"
              data-wow-delay="0.3ms"
              data-wow-duration="1000ms"
            >
              <iframe
                title="map"
                className="map-content"
                src="https://www.google.com/maps/embed/v1/place?q=Infomart,+1950+N+Stemmons+Fwy+suite+1034,+Dallas,+TX+75207,+United+States&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                width="1720"
                height="655"
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <Partner data={dataPartner} />
    </div>
  );
}

export default AUP;
