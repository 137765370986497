import React from "react";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/data/data-partner";

function SLA(props) {
  return (
    <div>
      <PageTitle
        title="Service Level Aggreement"
        subtitle="Ammended: 30th June 2022"
      />

      <section className="tf-section team-detail ">
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-12">
              <div
                className="info-detail"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <p className="sub">Service Level Aggreement</p>
                <div className="box">
                  <h2>Overview</h2>
                  <blockquote>
                    We reserve the right to update or alter any part of
                    VastNode's SLA without notice at any given time. NOTE:
                    VastNodes system of monitoring shall be the exclusive
                    monitoring system used to verify SLA breaches or downtime.
                    No third party monitoring systems shall be recognized.
                  </blockquote>
                  <h2>Coverage</h2>
                  <p>
                    VastNode allows 15 minutes before a client may request for
                    SLA credit. After 15 minutes have occured the client may
                    claim 1% for every minute of downtime there after. SLA is
                    capped at 60% of the monthly total bill.
                  </p>
                  <h2>SLA Limitations</h2>
                  <p>
                    Service Level Agreement will not be applicable for the
                    following events.
                  </p>
                  <ul>
                    <li>
                      <p>
                        Acts of God; Affecting services of any kind, such as
                        natural disastous.
                      </p>
                    </li>
                    <li>
                      <p>
                        Criminal Activity affecting service such as incoming /
                        outgoing DDos attacks.
                      </p>
                    </li>
                    <li>
                      <p>
                        Maintenance; Any form of scheduled Maintenance affecting
                        services.
                      </p>
                    </li>
                    <li>
                      <p>
                        Destruction; Any form of destruction/fires affecting
                        services.
                      </p>
                    </li>
                    <li>
                      <p>
                        Any service exceeding allocated resources provided to
                        their services which may cause issues or outages.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="box contact">
                  <div className="h7">Contact</div>
                  <p>
                    Infomart, 1950 N Stemmons Fwy suite 1034, Dallas, TX 75207,
                    United States
                  </p>
                  <a href="mailto:support@vastnode.net">
                    <p>support@vastnode.net</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-section tf-contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div
                className="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="tf-title st2 m-b17">
                  <h4 className="title">Have a question </h4>
                </div>
                <p className="m-r-40">
                  Fill up a Form and our team will get back to within 24 hrs
                </p>
                <a href="#" className="tf-button btn-effect">
                  <span className="boder-fade"></span>
                  <span className="effect">Send Message</span>
                </a>
              </div>
            </div>
            <div className="col-xl-7  col-md-12">
              <div
                className="contact-details"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div className="adress wrap-fx">
                  <div className="location">
                    <h6>Location</h6>
                    <ul>
                      <li>
                        Infomart, 1950 <br /> N Stemmons Fwy suite 1034, Dallas,
                        TX 75207, United States
                      </li>
                    </ul>
                  </div>
                  <div className="mail">
                    <h6>Contact Us</h6>
                    <ul>
                      <li>
                        <a href="mailto:support@vastnode.net">
                          support@vastnode.net
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flat-map wow fadeIn animated"
              data-wow-delay="0.3ms"
              data-wow-duration="1000ms"
            >
              <iframe
                title="map"
                className="map-content"
                src="https://www.google.com/maps/embed/v1/place?q=Infomart,+1950+N+Stemmons+Fwy+suite+1034,+Dallas,+TX+75207,+United+States&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                width="1720"
                height="655"
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <Partner data={dataPartner} />
    </div>
  );
}

export default SLA;
