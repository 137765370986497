import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

function PageTitle(props) {
  const { title, subtitle, img } = props;

  return (
    <section
      className="page-title"
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.9)), url(${img})`,
        backgroundPosition: "center",
        height: "512px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="breadcrumbs"
              data-aos="zoom-in"
              data-aos-duration="800"
            >
              <h3>{title}</h3>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageTitle;
