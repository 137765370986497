import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

ProductItem.propTypes = {
  item: PropTypes.object,
};

function ProductItem(props) {
  const { item } = props;

  return (
    <div className="col-md-6 col-lg-3 col-sm-12">
      <div key={item.id} className={`box-text corner-box ${item.class}`}>
        <div className="h7">{item.title}</div>
        <p style={{ whiteSpace: "pre-wrap" }}>
          {item.desc} <br />
          Located in Dallas, Texas.
        </p>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            gap: "1rem",
          }}
        >
          <p
            style={{
              fontSize: "1.6rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <strong style={{ color: "white" }}> {item.price}</strong>
            <span style={{ fontSize: "1.4rem" }}>{item.billing}</span>
          </p>
          {/* <Button href={item.url}>Buy</Button> */}
          <a
            href={item.url}
            className="tf-button btn-effect"
            style={{
              border: "solid 2px #fff",
              margin: "0px",
            }}
          >
            <span className=""></span>
            <span className="effect">Buy</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
