const ark = [
  {
    id: 1,
    title: 'Rust-01',
    desc:
      '2 vCore\n8 GB RAM\n40 GB NVMe\n1 Gbps unlimited bandwidth\n1 Backup slot\n2 MySQL\nPath DDoS Protection',
    price: '$16 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/rust/rust-01',
  },
  {
    id: 2,
    title: 'Rust-02',
    desc:
      '4 vCore\n16 GB RAM\n80 GB NVMe\n1 Gbps unlimited bandwidth\n1 Backup slot\n4 MySQL\nPath DDoS Protection',
    price: '$32 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/rust/rust-02',
  },
  {
    id: 3,
    title: 'Rust-03',
    desc:
      '6 vCore\n32 GB RAM\n100 GB NVMe\n1 Gbps unlimited bandwidth\n1 Backup slot\nUnlimited MySQL\nPath DDoS Protection',
    price: '$48 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/rust/rust-03',
  },
  {
    id: 4,
    title: 'Rust-04',
    desc:
      '8 vCore\n48 GB RAM\n200 GB NVMe\n1 Gbps unlimited bandwidth\n1 Backup slot\nUnlimited MySQL\nPath DDoS Protection',
    price: '$72 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/rust/rust-04',
  },
  {
    id: 4,
    title: 'Custom Server',
    desc: 'Looking for something not listed here?',
    price: 'Contact Us',
    billing: '',
    url: '/contact',
  },
]

export default ark
