const dataFaq = [
  {
    id: 1,
    title: 'How long does it take for a service to be delivered?',
    content:
      'It is generally deployed instantly however in some cases it might take upto 24 hours.',
    show: 'show',
  },
  {
    id: 2,
    title: 'Is DDoS protection included?',
    content: 'Yes DDoS protection is included for free',
  },
  {
    id: 3,
    title: 'What games do you all host?',
    content:
      'Currently we offer the following games MineCraft, CSGO, Ark: Survival Evolved, Garrys Mod, Rust and soon to come many many more.',
  },
  {
    id: 4,
    title: 'Bandwidth Upgrades?',
    content:
      'Yes, We offer some of the cheapest bandwidth upgrades anywhere. contact us today for pricing on your specific needs.',
  },
]

export default dataFaq
