const dataAbout = [
  {
    id: 1,
    title: '99.9% Uptime Gaurenteed',
    desc:
      'Rest assured, our meticulously engineered network is designed to consistently exceed expectations, ensuring that our Service Level Agreement (SLA) is not just met, but consistently surpassed.',
    class: 'active',
  },
  {
    id: 2,
    title: 'DDoS protection',
    desc:
      'We have you covered with DDoS protection FREE of charge, with the worlds leading ddos provider.',
  },
  {
    id: 3,
    title: 'Enterprise Hardware',
    desc:
      'We only use Enterprise hardware, to provide the best experince at a affordable price!',
  },
]

export default dataAbout
