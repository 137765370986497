const vps = [
  {
    id: 1,
    title: 'VPS-01',
    desc:
      '1 vCore\n50GB Disk\n1GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$2.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-01',
  },
  {
    id: 2,
    title: 'VPS-02',
    desc:
      '2 vCore\n50GB Disk\n2GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$4.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-02',
  },
  {
    id: 3,
    title: 'VPS-03',
    desc:
      '4 vCore\n100GB Disk\n4GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$8.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-03',
  },
  {
    id: 4,
    title: 'VPS-04',
    desc:
      '6 vCore\n150GB Disk\n8GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$14.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-04',
  },
  {
    id: 5,
    title: 'VPS-05',
    desc:
      '8 vCore\n200GB Disk\n12GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$18.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-05',
  },
  {
    id: 6,
    title: 'VPS-06',
    desc:
      '8 vCore\n250GB Disk\n16GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$20.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-06',
  },
  {
    id: 7,
    title: 'VPS-07',
    desc:
      '12 vCore\n300GB Disk\n32GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$32.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-07',
  },
  {
    id: 8,
    title: 'VPS-08',
    desc:
      '16 vCore\n500GB Disk\n64GB Ram\nUnmetered Bandwidth @ 1Gbps\n1 IPv4\n10 tbps Path DDos Protection',
    price: '$50.00 USD',
    billing: 'Monthly',
    url: 'https://portal.vastnode.net/index.php?rp=/store/vps-hosting/vps-08',
  },
]

export default vps
