import React from "react";
import PageTitle from "../components/pagetitle";
import Partner from "../features/partner";
import dataPartner from "../assets/data/data-partner";

function AboutUs() {
  return (
    <div>
      <PageTitle
        title="About us"
        subtitle="We would love to bring in
                    the best gaming experience and help those looking to grow in
                    this industry."
      />

      <section className="tf-section team-detail ">
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-xl-5 col-lg-5 col-md-5">
              <div
                className="image-details"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <img
                  src={require("../assets/images/common/servers.jpg")}
                  alt="servers"
                  style={{ height: "960px" }}
                />
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7">
              <div
                className="info-detail"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <p className="sub">GAME HOSTING</p>
                <h4 className="name">VastNode</h4>
                <div className="box">
                  <div className="h7">Some Thing About us</div>
                  <p>
                    Our services are built and managed with peek performance in
                    mind. Our goal is to provide each client with the best
                    experience possible. As we continue our growth and
                    development so will our clients. We would love to bring in
                    the best gaming experience and help those looking to grow in
                    this industry. If you are interested in developing your
                    skills check out our soon to come tournaments and team play
                    in some of our games.
                  </p>
                  <p>Contact us today for more information!</p>
                </div>
                <div className="box contact">
                  <div className="h7">Contact</div>
                  <p>
                    Infomart, 1950 N Stemmons Fwy suite 1034, Dallas, TX 75207,
                    United States
                  </p>
                  <a href="mailto:support@vastnode.net">
                    <p>support@vastnode.net</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-section tf-contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div
                className="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="tf-title st2 m-b17">
                  <h4 className="title">Have a question </h4>
                </div>
                <p className="m-r-40">
                  Fill up a Form and our team will get back to within 24 hrs
                </p>
                <a href="#" className="tf-button btn-effect">
                  <span className="boder-fade"></span>
                  <span className="effect">Send Message</span>
                </a>
              </div>
            </div>
            <div className="col-xl-7  col-md-12">
              <div
                className="contact-details"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div className="adress wrap-fx">
                  <div className="location">
                    <h6>Location</h6>
                    <ul>
                      <li>
                        Infomart, 1950 <br /> N Stemmons Fwy suite 1034, Dallas,
                        TX 75207, United States
                      </li>
                    </ul>
                  </div>
                  <div className="mail">
                    <h6>Contact Us</h6>
                    <ul>
                      <li>
                        <a href="mailto:support@vastnode.net">
                          support@vastnode.net
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flat-map wow fadeIn animated"
              data-wow-delay="0.3ms"
              data-wow-duration="1000ms"
            >
              <iframe
                title="map"
                className="map-content"
                src="https://www.google.com/maps/embed/v1/place?q=Infomart,+1950+N+Stemmons+Fwy+suite+1034,+Dallas,+TX+75207,+United+States&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                width="1720"
                height="655"
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <Partner data={dataPartner} />
    </div>
  );
}

export default AboutUs;
