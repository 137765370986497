import img1 from '../images/post/imgpost11.jpg'
import img2 from '../images/post/imgpost13.jpg'
import img3 from '../images/post/imgpost12.jpg'

const dataBlog = [
  {
    id: 1,
    img: img1,
    category: 'GAMING',
    title: 'Gaming with Attitude',
    time: '20 Jun 2022',
    text:
      'VastNode will be organizing tournaments for popular titles such as CS:GO, Minecraft, ARK, etc. Participate to win exciting rewards.',
    link: 'https://portal.vastnode.net/commercial_2.mp4',
  },
  {
    id: 2,
    img: img2,
    category: 'RE-BRAND',
    title: 'VastNode has re-branded ',
    time: 'Q1 2023',
    text:
      'Website update, management change, network upgrade, pricing restructure and many more changes',
    link: 'https://portal.vastnode.net/commercial_1.mp4',
  },
]

export default dataBlog
