import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "react-modal-video/scss/modal-video.scss";
// import { Modal } from "react-bootstrap";
import "./styles.scss";

SliderItem.propTypes = {
  item: PropTypes.object,
};

function SliderItem(props) {
  const { item } = props;

  const [isOpen, setOpen] = useState(false);

  // const [modalShow, setModalShow] = useState(false);

  return (
    <div className={`box-slider ${item.classAction}`}>
      <img
        className="bg-slider"
        src={item.bgImg}
        alt="vastnode"
        style={{ filter: "brightness(40%)" }}
      />
      <div className="box-slider__main">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-box">
                <h1 className="title">{item.title}</h1>
                <p className="sub-title">{item.desc}</p>
                <div className="wrap-btn">
                  <Link
                    to={item.link1}
                    className="tf-button-st2 btn-effect"
                    data-toggle="modal"
                    data-target="#popup_bid"
                  >
                    <span className="effect">{item.link1heading}</span>
                  </Link>
                  {item.link2 ? (
                    <Link
                      to={item.link2}
                      className="tf-button btn-effect popup-youtube"
                    >
                      <span className="boder-fade"></span>
                      <span className="effect">{item.link2heading}</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="image">
                <img src={item.img} alt="cybox" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderItem;
