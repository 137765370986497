const budgetMinecraft = [
  {
    id: 1,
    title: 'MC-01',
    desc:
      '1 vCore\n1 GB RAM\n15 GB NVMe\n1 Gbps unlimited bandwidth\n1 Backup slot\n2 MySQL\nPath DDoS Protection',
    price: '$1.50 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-01',
  },
  {
    id: 2,
    title: 'MC-02',
    desc:
      '1 vCore\n2 GB RAM\n30 GB NVMe\n1 Gbps unlimited bandwidth\n2 Backup slots\n4 MySQL\nPath DDoS Protection',
    price: '$3.00 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-02-1',
  },
  {
    id: 3,
    title: 'MC-03',
    desc:
      '2 vCore\n4 GB RAM\n50 GB NVMe\n1 Gbps unlimited bandwidth\n3 Backup slots\n6 MySQL\nPath DDoS Protection',
    price: '$6.00 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-03-1',
  },
  {
    id: 4,
    title: 'MC-04',
    desc:
      '2 vCore\n8 GB RAM\n75 GB NVMe\n1 Gbps unlimited bandwidth\n4 Backup slots\n8 MySQL\nPath DDoS Protection',
    price: '$12.00 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-04-1',
  },
  {
    id: 5,
    title: 'MC-05',
    desc:
      '4 vCore\n12 GB RAM\n200 GB NVMe\n1 Gbps unlimited bandwidth\n5 Backup slots\n10 MySQL\nPath DDoS Protection',
    price: '$16.00 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-05-1',
  },
  {
    id: 6,
    title: 'MC-06',
    desc:
      '6 vCores\n16 GB RAM\n200 GB NVMe\n1 Gbps unlimited bandwidth\n6 Backup slots\nUnlimited MySQL\nPath DDoS Protection',
    price: '$20.00 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-06-1',
  },
  {
    id: 7,
    title: 'MC-07',
    desc:
      '6 vCores\n20 GB RAM\n250 GB NVMe\n1 Gbps unlimited bandwidth\n7 Backup slots\nUnlimited MySQL\nPath DDoS Protection',
    price: '$24.00 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-07-1',
  },
  {
    id: 8,
    title: 'MC-08',
    desc:
      '8 vCores\n32 GB RAM\n250 GB NVMe\n1 Gbps unlimited bandwidth\n8 Backup slots\nUnlimited MySQL\nPath DDoS Protection',
    price: '$38.00 USD',
    billing: 'Monthly',
    url:
      'https://portal.vastnode.net/index.php?rp=/store/minecraft-hosting/mc-08-1',
  },
]

export default budgetMinecraft
